import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import thumbup from "../components/assets/img/thumbup.png"

const ThankYouPage = () => (
  <Layout>
    <SEO title="Thank you!" />
    <div className="container text-center my-5">
      <h1 style={{ fontSize: "70px" }}>Thank you!</h1>
      <img src={thumbup} alt="thumbup" />
    </div>

    <div
      className="bg-light pt-5 text-center"
      style={{ height: "60vh", position: "relative" }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "0",
          right: "0",
          transform: "perspective(1px) translateY(-50%)",
        }}
      >
        <div className="container">
          <h3 className="pb-4">
            We received your message and will get back to you shortly.
          </h3>
          <Link className="btn btn-primary btn-lg" role="button" to="/">
            Take Me Home
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default ThankYouPage
